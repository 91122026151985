import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileInvoice,
  faCircleCheck,
  faBan,
} from '@fortawesome/free-solid-svg-icons'
import { List, notification, Button } from 'antd'
import { endOfMonth, format, getMonth, getYear, subMonths } from 'date-fns'
import { db, functions } from '~/firebase-app'
import { query, collection, where, orderBy } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { cCustomer, Customer } from '~/models/Customer'
import { cInvoice, Invoice } from '~/models/Invoice'
import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import useCollectionSubscription from './hooks/useCollectionSubscription'
import { cCustomerProduct, CustomerProduct } from '~/models/CustomerProduct'

const DATE_SG_ACCOUNTING_FORMAT = 'yyyy/MM/dd'

type Props = {
  customer: Customer
}

export const CustomerView: FC<Props> = (props) => {
  const { customer } = props
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const customerId = customer.id
  const today = new Date()
  const invoiceDate = endOfMonth(subMonths(today, 1)) // 先月末尾
  const year = getYear(invoiceDate)
  const month = getMonth(invoiceDate) + 1

  const customerProductsQuery = query(
    collection(db, cCustomer, customerId, cCustomerProduct),
    where('isAvailable', '==', true),
    orderBy('product.serviceType', 'asc'),
  )
  const customerProducts = useCollectionSubscription(
    customerProductsQuery,
    CustomerProduct,
  )

  const invoicesQuery = query(
    collection(db, cInvoice),
    where('customerId', '==', customerId),
    where('year', '==', year),
    where('month', '==', month),
  )
  const invoices = useCollectionSubscription(invoicesQuery, Invoice)

  const hasInvoice = invoices.length > 0
  const hasCustomerProduct = customerProducts.length > 0

  const canInvoice = !hasInvoice && hasCustomerProduct

  const handleCreateInvoice = async (e: any) => {
    const createInvoice = httpsCallable<
      { customerId: string; invoiceDateString: string },
      { warningMessage: string }
    >(functions, 'createInvoice')
    const invoiceDateString = format(invoiceDate, DATE_SG_ACCOUNTING_FORMAT)

    try {
      setIsLoading(true)
      const { data } = await createInvoice({
        customerId: customerId,
        invoiceDateString,
      })
      if (data.warningMessage) {
        notification.warning({
          message: data.warningMessage,
        })
      }
    } catch (error) {
      if (error instanceof Error) {
        notification.error({
          message: error.message,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <List.Item>
        <div className="flex items-baseline justify-between w-full">
          <Link to={`/admin/customers/${customerId}`}>
            <p> {customer.name}</p>
          </Link>
          <div className="flex items-center">
            {customerProducts.map((customerProduct) => {
              const serviceTypeIcon =
                customerProduct.product.getServiceTypeIcon()
              return (
                <FontAwesomeIcon
                  key={customerProduct.id}
                  icon={serviceTypeIcon}
                  className="pl-1"
                />
              )
            })}
            <div className="w-11 h-8 flex justify-center items-center">
              {hasInvoice && (
                <FontAwesomeIcon color="green" icon={faCircleCheck} />
              )}
              {canInvoice && (
                <Button
                  onClick={handleCreateInvoice}
                  loading={isLoading}
                  type="text"
                  icon={<FontAwesomeIcon color="green" icon={faFileInvoice} />}
                />
              )}
              {!hasInvoice && !hasCustomerProduct && (
                <FontAwesomeIcon icon={faBan} />
              )}
            </div>
          </div>
        </div>
      </List.Item>
    </>
  )
}
