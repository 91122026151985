import { Layout } from 'antd'
import { FC } from 'react'
import { Outlet } from 'react-router'

const JibunSeriesHeader = () => {
  return (
    <Layout.Header
      style={{ zIndex: 1, width: '100%', height: 80 }}
      className="header no-print bg-white text-center "
    >
      <div className="container h-7 flex items-center justify-center ">
        <div className="flex justify-center">
          <img src="/jibunseries_S.png" alt="じぶんシリーズ" />
        </div>
      </div>
    </Layout.Header>
  )
}

type Props = {}

export const JibunEstimateHeaderLayout: FC<Props> = (props) => {
  return (
    <Layout className="min-h-screen">
      <JibunSeriesHeader />
      <Layout.Content>
        <Outlet />
      </Layout.Content>
    </Layout>
  )
}
