import { Drawer, Tabs, TabsProps } from 'antd'
import { db } from '~/firebase-app'
import { query, collection, orderBy, where } from 'firebase/firestore'
import { cCustomer } from '~/models/Customer'
import { cCustomerProduct, CustomerProduct } from '~/models/CustomerProduct'
import { FC, useState } from 'react'
import { useToggle } from 'react-use'
import { CustomerProductView } from './CustomerProductView'
import { CustomerProductForm } from './forms/CustomerProductForm'
import useCollectionSubscription from './hooks/useCollectionSubscription'

type Props = {
  customerId: string
}

export const CustomerProductsTabs: FC<Props> = (props) => {
  const { customerId } = props
  const defaultActiveKey = '0'
  const [activeTabKey, setActiveTabKey] = useState<string>(defaultActiveKey)
  const [isVisibleCustomerProductUpdateForm, toggleCustomerProductUpdateForm] =
    useToggle(false)

  const customerProductQuery = query(
    collection(db, cCustomer, customerId, cCustomerProduct),
    where('isAvailable', '==', true),
    orderBy('contractedDate', 'asc'),
  )

  const customerProducts = useCollectionSubscription(
    customerProductQuery,
    CustomerProduct,
  )

  const selectedCustomerProduct = (): CustomerProduct | undefined => {
    const findCustomerProduct = customerProducts[Number(activeTabKey)]
    return findCustomerProduct
  }

  const items: TabsProps['items'] = customerProducts.map((customerProduct) => {
    return {
      key: customerProduct.id,
      label: customerProduct.product.name,
      children: (
        <CustomerProductView
          customerProduct={customerProduct}
          toggleCustomerProductUpdateForm={toggleCustomerProductUpdateForm}
          customerId={customerId}
        />
      ),
    }
  })

  return (
    <>
      <Tabs
        defaultActiveKey={defaultActiveKey}
        onChange={setActiveTabKey}
        items={items}
      ></Tabs>
      <Drawer
        title="商品編集フォーム"
        width={720}
        onClose={toggleCustomerProductUpdateForm}
        open={isVisibleCustomerProductUpdateForm}
        styles={{ body: { paddingBottom: 80 } }}
      >
        <div className="py-16">
          <CustomerProductForm
            toggleCustomerProductForm={toggleCustomerProductUpdateForm}
            customerId={customerId}
            customerProduct={selectedCustomerProduct()}
            initialValues={{
              tenantId: selectedCustomerProduct()?.tenantId || '',
              expectedUserCounts: String(
                selectedCustomerProduct()?.expectedUserCounts,
              ),
            }}
          />
        </div>
      </Drawer>
    </>
  )
}
