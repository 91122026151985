import { Link } from 'react-router-dom'
import { Layout, Menu, MenuProps } from 'antd'
import { useContext } from 'react'
import { FirebaseContext } from './contexts/FirebaseContext'
type MenuItem = Required<MenuProps>['items'][number]

export default function Sider(props: any) {
  const { firebaseUser, isInitializing } = useContext(FirebaseContext)
  const isLoggedIn = !!firebaseUser

  const items: MenuItem[] =
    !isInitializing && isLoggedIn
      ? [
          {
            key: 'じぶんシリーズ見積もり作成',
            label: (
              <Link to={'/jibun/estimates/new'}>
                <p className="font-bold">じぶんシリーズ見積もり作成</p>
              </Link>
            ),
          },
          {
            key: '顧客一覧',
            label: (
              <Link to={'/admin/customers'}>
                <p className="font-bold">顧客一覧</p>
              </Link>
            ),
          },
        ]
      : [
          {
            key: 'じぶんシリーズ見積もり作成',
            label: (
              <Link to={'/jibun/estimates/new'}>
                <p className="font-bold">じぶんシリーズ見積もり作成</p>
              </Link>
            ),
          },
        ]

  return (
    <Layout.Sider width={250} className="no-print">
      <Menu
        mode="inline"
        style={{ height: '100%', borderRight: 0 }}
        items={items}
      />
    </Layout.Sider>
  )
}
