import { Button, Drawer, List, Typography } from 'antd'
import { CustomerView } from '~/components/CustomerView'
import { CustomerForm } from '~/components/forms/CustomerForm'
import { SuzyCustomerForm } from '~/components/forms/SuzyCustomerForm'
import { AuthHoc } from '~/components/hocs/AuthHoc'
import { db } from '~/firebase-app'
import { query, collection, orderBy, getDocs } from 'firebase/firestore'
import { cCustomer, Customer } from '~/models/Customer'
import { useEffect, useState } from 'react'
import { useToggle } from 'react-use'
const { Title } = Typography

export const CustomerIndex = AuthHoc((props: any) => {
  const [customers, setCustomers] = useState<Customer[]>([])

  useEffect(() => {
    ;(async () => {
      const customersQuery = query(
        collection(db, cCustomer),
        orderBy('createdDate', 'desc'),
      )
      const { docs: customerDocs } = await getDocs(customersQuery)
      const customers = customerDocs.map(
        (doc) => new Customer({ id: doc.id, ...doc.data() }),
      )

      setCustomers(customers)
    })()
  }, [])

  const [isVisibleCustomerForm, toggleCustomerForm] = useToggle(false)

  const [isVisibleSuzyCustomerForm, toggleSuzyCustomerForm] = useToggle(false)
  const callbackCustomer = (customer: Customer) => {
    setCustomers((prev) => [customer, ...prev])
  }

  return (
    <div className="container py-32 max-w-screen-sm">
      <div className="flex justify-center">
        <Title level={3} className="w-fit">
          <u>顧客一覧ページ</u>
        </Title>
      </div>

      <div className="flex py-8">
        <div className="pr-8">
          <Button type="primary" onClick={toggleCustomerForm}>
            SUZYから顧客情報を取り込む
          </Button>
        </div>
        <Drawer
          title="新規顧客フォーム"
          width={720}
          onClose={toggleCustomerForm}
          open={isVisibleCustomerForm}
          styles={{ body: { paddingBottom: 80 } }}
        >
          <div className="py-16">
            <CustomerForm
              callbackCustomer={callbackCustomer}
              toggleForm={toggleCustomerForm}
            />
          </div>
        </Drawer>

        <div className="">
          <Button type="default" onClick={toggleSuzyCustomerForm}>
            SUZYに顧客情報を追加する
          </Button>
        </div>
        <Drawer
          title="SUZY新規顧客フォーム"
          width={720}
          onClose={toggleSuzyCustomerForm}
          open={isVisibleSuzyCustomerForm}
          styles={{ body: { paddingBottom: 80 } }}
        >
          <div className="py-16">
            <SuzyCustomerForm
              callbackCustomer={callbackCustomer}
              toggleForm={toggleSuzyCustomerForm}
            />
          </div>
        </Drawer>
      </div>

      <List
        className="bg-white"
        size="small"
        bordered
        dataSource={customers}
        renderItem={(customer) => (
          <CustomerView key={customer.id} customer={customer} />
        )}
      />
    </div>
  )
})
