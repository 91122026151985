import { Fragment } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Routes from '../routes'
import { FirebaseProvider } from './providers/FirebaseProvider'
import { ErrorBoundary } from './ErrorBoundary'

export default function Root(props: any) {
  return (
    <Fragment>
      <ErrorBoundary>
        <FirebaseProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </FirebaseProvider>
        <ToastContainer position={'top-center'} />
      </ErrorBoundary>
    </Fragment>
  )
}
